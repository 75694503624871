import { Rating } from "./Rating";
import CallAudio from "../CallAudio";

function secondsToMinute(seconds) {
  // 150 seconds = 02:30
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = (seconds % 60).toFixed(0);
  return `${minutes}:${remainingSeconds}`;
}

export function ReportOverview({ report, currentIndex, setCurrentIndex }) {
  return (
    <div className="bg-white shadow-[0px_4px_24px_0px_rgba(203,90,90,0.05)] p-6 rounded-[14px] flex flex-col gap-5 md:gap-0 md:flex-row md:items-center">
      <div className="flex-1">
        <div className="flex md:items-center gap-2 md:gap-[18px] flex-col md:flex-row">
          <div className="font-bold text-xl text-black">Praxis Score</div>
          <Rating value={report.score} />
        </div>
        <div className="mt-7 flex items-center gap-2 overflow-auto flex-wrap">
          <div className="w-fit px-4 py-1.5 rounded-3xl border border-[#DDD] justify-start items-center gap-1.5 flex">
            <div className="text-black/60 text-[15px]">Duration:</div>
            <div className="text-black text-[15px] font-medium whitespace-nowrap">
              {secondsToMinute(report.duration)} mins
            </div>
          </div>
          <div className="w-fit px-4 py-1.5 rounded-3xl border border-[#DDD] justify-start items-center gap-1.5 flex">
            <div className="text-black/60 text-[15px]">Sentiment:</div>
            <div className="text-black text-[15px] font-medium whitespace-nowrap">
              Positive 33%
            </div>
          </div>
          <div className="w-fit px-4 py-1.5 rounded-3xl border border-[#DDD] justify-start items-center gap-1.5 flex">
            <div className="text-black/60 text-[15px]">Filler:</div>
            <div className="text-black text-[15px] font-medium whitespace-nowrap">
              18%
            </div>
          </div>
          <div className="w-fit px-4 py-1.5 rounded-3xl border border-[#DDD] justify-start items-center gap-1.5 flex">
            <div className="text-black/60 text-[15px]">Questions:</div>
            <div className="text-black text-[15px] font-medium whitespace-nowrap">
              7%
            </div>
          </div>
        </div>
      </div>
      <div>
        <CallAudio
          conversations={report.conversations || []}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
        />
      </div>
    </div>
  );
}
