import { ReactComponent as DisconnectIcon } from "../../assets/icons/disconnect.svg";

export function ActionButtons({ onDisconnect }) {
  return (
    <div className="z-[2] justify-center w-full absolute bottom-12  items-start gap-7 flex">
      <button type="button" onClick={onDisconnect} className="group">
        <DisconnectIcon className="w-14 h-14 group-hover:[&_circle]:fill-white/90" />
      </button>
    </div>
  );
}
