export const BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL ||
  "https://uohrpxz635.execute-api.us-east-1.amazonaws.com/dev/tenant/good_pharma/";

// TODO: Invoke gateway url
export const BUCKET =
  process.env.REACT_APP_BUCKET || "praxispro-tenant-profiles";
export const ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
export const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
export const REGION = process.env.REACT_APP_REGION || "us-east-1";
export const IS_USE_BE = false;
