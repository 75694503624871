import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Dropdown } from "../Dropdown";
import { UserButton } from "../UserButton";
import { menuItems } from "./Sidebar";
import { Link, useLocation } from "react-router-dom";

const framerSidebarBackground = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0, transition: { delay: 0.1 } },
  transition: { duration: 0.3 },
};

const framerSidebarPanel = {
  initial: { x: "-100%" },
  animate: { x: 0 },
  exit: { x: "-100%" },
  transition: { duration: 0.3 },
};
export function MobileHeaderMenu({
  onClose,
  className = "",
  overlayClassName = "",
  onEdit,
  onLogout,
  userInfo = {},
}: {
  onClose: () => void;
  className?: string;
  overlayClassName?: string;
  onEdit: () => void;
  onLogout: () => void;
  userInfo: any;
}) {
  const location = useLocation();
  const isSelected = (path) => {
    if (location.pathname.includes(path)) {
      return true;
    }
    return false;
  };
  const [openDropdown, setOpenDropdown] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const disableScroll = () => {
    document.body.style.overflow = "hidden";
  };

  const enableScroll = () => {
    document.body.style.overflow = "";
  };

  useEffect(() => {
    disableScroll();
    return () => enableScroll();
  }, []);
  return (
    <>
      <motion.div
        {...framerSidebarBackground}
        className={`fixed z-[4] top-[69px] h-[100vh] left-0 right-0 bottom-0 bg-black/40 ${overlayClassName}`}
        onClick={handleClose}
      />
      <motion.div
        {...framerSidebarPanel}
        className={`fixed z-[5] top-[69px] h-[calc(100%-69px)] left-0 right-0 flex flex-col justify-between bg-[#7C2928] w-[70%] md:w-[40%] ${className}`}
      >
        <div className="px-6 mt-5">
          {menuItems.map((item, index) => (
            <Link
              to={item.path}
              key={index}
              className={`w-full px-6 py-5 rounded-[12px] justify-start items-center gap-3 flex group hover:bg-white/[0.08] transition duration-75 hover:scale-[1.02] ${
                isSelected(item.path) ? "!bg-white/[0.17]" : ""
              } `}
            >
              <item.Icon />

              <div className="grow shrink basis-0 text-white text-sm">
                {item.title}
              </div>
            </Link>
          ))}
        </div>
        <div className="relative">
          <UserButton
            user={userInfo}
            variant="sideMenu"
            onClick={() => setOpenDropdown(true)}
          />

          <Dropdown
            padding="15px"
            className="w-[calc(100%-32px)] !top-[-100px] left-4"
            isOpen={openDropdown}
            setIsOpen={() => setOpenDropdown(false)}
          >
            <button
              className="!pb-[2px] text-[#212424] text-base"
              onClick={() => {
                onClose();
                onEdit();
              }}
            >
              Edit Profile
            </button>
            <button
              className="text-[#E3270E] text-base pt-3"
              onClick={() => {
                onClose();
                onLogout();
              }}
            >
              Logout
            </button>
          </Dropdown>
        </div>
      </motion.div>
    </>
  );
}
