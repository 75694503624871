import hark from "./hark";
import { useState, useEffect, useRef } from "react";

export const useUserSpeakingDetector = ({ stream }) => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const harker = useRef(null);
  const destroy = () => {
    if (harker.current?.destroy) {
      harker.current.destroy();
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (stream) {
      harker.current = hark(stream, {
        interval: 0.01,
      });
      harker.current.on("speaking", () => setIsSpeaking(true));
      harker.current.on("stopped_speaking", () => setIsSpeaking(false));
    }
    return () => {
      if (harker.current?.destroy) {
        // harker.current.destroy();
      }
    };
  }, [stream, harker]);

  return { isSpeaking, destroy };
};
