import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/Button";
import { TextInput } from "../../../components/TextInput";
import React from "react";
import { api } from "../../../services/api";
import { setToken, setUser as setUserInfo } from "../../../services/utils";
import { ReactComponent as AlertIcon } from "../../../assets/icons/Alert.svg";
import { Password } from "../components/Password";
import { ReactComponent as PraxisProIcon } from "../../../assets/icons/logo-white-bg.svg";
import { ReactComponent as StatisticsIcon } from "../../../assets/icons/statistics.svg";

import { ShowToast } from "../../../services/toast";

export default function passwordValidation(password: string) {
  //   add special character
  const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
  return passwordRegex.test(password);
}

export function Register() {
  const navigate = useNavigate();
  const [errors, setErrors] = React.useState({} as any);
  const [disabled, setDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // get the form data
    const formData = new FormData(e.currentTarget);
    // convert the form data to an object
    const data = Object.fromEntries(formData);
    // log the results
    setError("");
    setErrors({});
    setLoading(true);
    try {
      const response = await api.signup({
        email: data.email,
        password: data.password,
        given_name: data.given_name,
        family_name: data.family_name,
        mobile: "1234567890",
      });
      const result = response;
      if (result?.accessToken) {
        ShowToast({
          type: "success",
          message: "Account created successfully",
        });
        setToken(result?.accessToken);
        if (result?.id) {
          const userResponse = await api.getUserProfile(result?.id);
          setUserInfo({ ...userResponse });
        }
        navigate("/app/praxisplay");
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.status === 401) {
        setError("Invalid email or password");
        return;
      }
      setError(error?.response?.data?.message || "Something went wrong");
      return false;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);
    if (
      e.target.name === "password" &&
      !passwordValidation(data.password.toString())
    ) {
      setErrors((prev) => ({
        ...prev,
        password:
          "Password should contain at least 8 characters, 1 letter, 1 number and 1 special character",
      }));
    } else {
      setErrors((prev) => ({ ...prev, password: "" }));
    }
    if (
      data.email &&
      data.password &&
      data.given_name &&
      data.family_name &&
      data.terms &&
      passwordValidation(data.password.toString())
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  return (
    <div className="h-screen">
      <div className="flex items-center p-3.5 h-[100vh]">
        <div
          className="flex-1 h-[calc(100vh-28px)] relative hidden lg:flex flex-col rounded-[23px] items-center justify-center"
          style={{
            backgroundSize: "cover",
            background:
              "linear-gradient(131.57deg, #6E1336 2.11%, #CD3B1A 88.06%)",
          }}
        >
          <PraxisProIcon className="absolute top-[100px] tall:!top-[80px]" />
          <div className="flex-1 w-[calc(100%-150px)] max-h-[calc(100%-275px)] mx-auto gap-16 flex flex-col items-center justify-center">
            <StatisticsIcon className="z-[2] w-full h-auto" />
          </div>
        </div>
        <div className="flex-1 h-[100vh] lg:h-auto flex flex-col items-center justify-center overflow-auto px-4 md:px-0">
          <div className="bg-white tall2:min-h-[calc(100vh-75px)] py-8 md:p-8 lg:w-full lg:max-w-[80%] rounded-[16px]">
            <h1 className="text-[44px] font-bold">Get Started</h1>
            <div className="flex items-center gap-1 mt-1 text-[22px]">
              <span className="text-black">Already have an account?</span>
              <a href="/login" className="text-primaryLink font-semibold">
                Log in
              </a>
            </div>
            <div className="border-b border-[#DDD] w-full my-7"></div>
            <form
              className="mt-4"
              onSubmit={handleSubmit}
              onChange={handleChange}
            >
              {error && (
                <div className="text-red-500 text-xs font-medium mb-2 flex items-center gap-1">
                  <AlertIcon /> {error}
                </div>
              )}
              <div className="mb-4 flex items-center gap-4">
                <div className="flex-1 flex flex-col gap-1.5">
                  <label htmlFor="email" className="text-sm text-black/80">
                    First Name
                  </label>
                  <TextInput
                    placeholder="Enter first name"
                    type="text"
                    name="given_name"
                    maxLength={128}
                  />
                </div>
                <div className="flex-1 flex flex-col gap-1.5">
                  <label htmlFor="email" className="text-sm text-black/80">
                    Last Name
                  </label>
                  <TextInput
                    placeholder="Enter last name"
                    type="text"
                    name="family_name"
                    maxLength={128}
                  />
                </div>
              </div>
              <div className="mb-4 flex flex-col gap-1.5">
                <label htmlFor="email" className="text-sm text-black/80">
                  Email
                </label>
                <TextInput
                  placeholder="Enter your email address"
                  type="email"
                  name="email"
                />
              </div>
              <div className=" flex flex-col gap-1.5">
                <label htmlFor="password" className="text-sm text-black/80">
                  Password
                </label>
                <Password
                  type="password"
                  name="password"
                  placeholder="Create a password"
                />
                {errors.password && (
                  <div className="text-red-500 text-xs font-medium">
                    {errors.password}
                  </div>
                )}
              </div>
              <div className="flex items-center mt-[14px]">
                <input
                  id="terms"
                  name="terms"
                  type="checkbox"
                  className="w-[22px] h-[22px]"
                />
                <label
                  htmlFor="terms"
                  className="text-[#1D1E1B] text-base ml-3 md:ml-1.5"
                >
                  I agree to the{" "}
                  <a href="/terms" className="text-blueLink">
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a href="/privacy" className="text-blueLink">
                    Privacy Policy
                  </a>
                </label>
              </div>
              <div className="mt-8 tall:pb-10">
                <Button
                  type="submit"
                  variant="primary"
                  className="!rounded-full w-full"
                  disabled={disabled || loading}
                  loading={loading}
                >
                  Create account
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
