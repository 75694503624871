import React, { useEffect, useRef, useState } from "react";
import { getTenantKey, getUser } from "./utils";
import { api } from "./api";
import useFetchS3Json from "./useFetchS3Json";
import { ACCESS_KEY_ID, BUCKET, REGION, SECRET_ACCESS_KEY } from "../env";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { useNavigate } from "react-router-dom";

export const AppContext = React.createContext({});

export function AppProvider({ children }) {
  const navigate = useNavigate();
  const key = `${getTenantKey()}/configuration/profile.json`;
  const {
    data: profile,
    loading,
    error,
  } = useFetchS3Json(BUCKET, key, ACCESS_KEY_ID, SECRET_ACCESS_KEY, REGION);
  const cognitoUserRef = useRef<any>();
  const userPoolRef = useRef<any>();
  const [userSession, setUserSession] = useState<any>();
  const [userSessionLoading, setUserSessionLoading] = useState<boolean>(false);

  const refreshUserSession = () => {
    if (cognitoUserRef.current) {
      cognitoUserRef.current.refreshSession(
        userSession.refreshToken,
        (err, session) => {
          if (err) {
            console.log(err);
          }
          setUserSession(session);
        }
      );
    }
  };

  const logout = () => {
    if (cognitoUserRef.current) {
      cognitoUserRef.current.globalSignOut({
        onSuccess: () => {
          setUserSession(null);
          navigate("/login");
        },
        onFailure: (err) => {
          console.log(err);
        },
      });
    }
  };

  const fetchUserSession = () => {
    if (profile) {
      setUserSessionLoading(true);
      const poolData = {
        UserPoolId: (profile as any)?.user_pool_id,
        ClientId: (profile as any)?.user_pool_client_id,
      };
      userPoolRef.current = new CognitoUserPool(poolData);
      var cognitoUser = userPoolRef.current.getCurrentUser();
      if (cognitoUser != null) {
        cognitoUser.getSession(function (err, session) {
          setUserSessionLoading(false);
          if (err) {
            console.log(err);
            const isPublicRoutes = ["/login", "/forgot-password"];
            if (isPublicRoutes.includes(window.location.pathname)) {
              return;
            }
            navigate("/login");
          }
          if (session) {
            cognitoUserRef.current = cognitoUser;
            // console.log("session validity: ", session);
            setUserSession(session);
            navigate("/praxisplay");
          }
          // Instantiate aws sdk service objects now that the credentials have been updated.
          // example: var s3 = new AWS.S3();
        });
      } else {
        // navigate("/login");
        setUserSessionLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchUserSession();
  }, [profile]);

  const values = {
    tenantLoading: loading,
    tenantProfile: profile,
    tenantError: error,
    cognitoUserRef,
    userPool: userPoolRef.current,
    userSession,
    userSessionLoading,
    logout,
    refreshUserSession,
    fetchUserSession,
  };

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
}

export function useApp(): {
  tenantLoading: boolean;
  tenantProfile: any;
  tenantError: any;
  cognitoUserRef: any;
  userPool: any;
  userSession: any;
  userSessionLoading: boolean;
  refreshUserSession: () => void;
  logout: () => void;
  fetchUserSession: () => void;
} {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error("useApp must be used within a AppProvider");
  }
  return context as {
    tenantLoading: boolean;
    tenantProfile: any;
    tenantError: any;
    cognitoUserRef: any;
    userPool: any;
    userSession: any;
    userSessionLoading: boolean;
    logout: () => void;
    refreshUserSession: () => void;
    fetchUserSession: () => void;
  };
}
