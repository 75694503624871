import { Button } from "../../components/Button";
import Modal from "../../components/Modal";
import { ReactComponent as RandomIcon } from "../../assets/icons/random.svg";
import { ReactComponent as MinusIcon } from "../../assets/icons/minus.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";
import { useEffect, useMemo, useState } from "react";
import { SliderView } from "./components/Slider";
import {
  ObjectionLevels,
  PersonalityTypes,
  PractitionerTypes,
} from "../../constant";
import { useApp } from "../../services/app-context";

const practitionerTypes = Object.values(PractitionerTypes);

const objectionLevels = Object.values(ObjectionLevels);

// const personalityTypes = Object.values(PersonalityTypes);

function Heading({ title, subtitle }: { title: string; subtitle?: string }) {
  return (
    <div>
      <span className="text-black text-base font-semibold">{title}</span>
      <span className="text-black text-base font-bold"> </span>
      {!!subtitle && (
        <span className="text-black text-base font-normal">({subtitle})</span>
      )}
    </div>
  );
}

function SelectButton({
  title,
  selected,
  className = "",
  onClick,
}: {
  title: string;
  selected?: boolean;
  className?: string;
  onClick?: () => void;
}) {
  return (
    <button
      type="button"
      className={`text-[15px] hover:shadow font-medium py-3.5 px-[18px] rounded-[10px] ${
        selected
          ? "border border-[#7C2928] bg-[#FEE] text-[#7C2928]"
          : "text-[#434343] bg-[#F3F1F2]"
      } ${className}`}
      onClick={onClick}
    >
      {title}
    </button>
  );
}

function Separator() {
  return <div className="my-[26px] border-[0.5px] border-[#DDD] mx-5" />;
}

type Form = {
  practitionerType: string;
  objectionLevel: string;
  personalityType: string[];
  beginTime: number;
  isPatientBased: boolean | string;
};

export function CustomizerModal({
  onClose,
  isOpen,
  onContinue,
  data,
}: {
  onClose: () => void;
  isOpen: boolean;
  onContinue: (customizeForm: Form) => void;
  data: Form;
}) {
  const { tenantProfile } = useApp();
  const [form, setForm] = useState<Form>({
    practitionerType: "",
    objectionLevel: "M",
    personalityType: [],
    beginTime: 0,
    isPatientBased: "",
  });

  const handlePatientChange = (value: string) => {
    setForm((prev: Form) => ({ ...prev, isPatientBased: value === "Patient" }));
  };

  const handleChange = (value: string | number, key: string) => {
    if (key === "personalityType") {
      const index = form.personalityType.indexOf(value as string);
      if (index > -1) {
        setForm((prev: Form) => ({
          ...prev,
          personalityType: form.personalityType.filter(
            (type: string) =>
              type?.toLowerCase() !== (value as string)?.toLowerCase()
          ),
        }));
      } else {
        setForm((prev: Form) => ({
          ...prev,
          personalityType: [...prev.personalityType, value as string],
        }));
      }
      return;
    }
    if (key === "beginTime") {
      const val = (value as number) > 7 ? 7 : value;
      setForm((prev: Form) => ({ ...prev, [key]: val as number }));
      return;
    }
    setForm((prev: Form) => ({ ...prev, [key]: value }));
  };

  function generateRandomTime() {
    // Generate random minutes between 1 and 5
    const minutes = Math.floor(Math.random() * 4) + 1;

    // Generate random seconds between 0 and 59
    const seconds = Math.floor(Math.random() * 60);

    // Format time string
    const timeString = `${minutes}.${seconds < 10 ? `0${seconds}` : seconds}`;
    return timeString;
  }

  const handleRandomize = () => {
    // generate random time in time based like 4.30 means 4 minutes & 30 seconda
    // maximum time limit is 5 minutes so it should be between 1 to 5 minutes
    generateRandomTime();
    setForm({
      practitionerType:
        practitionerTypes[Math.floor(Math.random() * practitionerTypes.length)],
      objectionLevel: objectionLevels[Math.floor(Math.random() * 3)],
      personalityType: [
        personalityTypes[Math.floor(Math.random() * personalityTypes.length)],
        personalityTypes[Math.floor(Math.random() * personalityTypes.length)],
      ],
      beginTime: Number(generateRandomTime()),
      isPatientBased: Math.random() > 0.5,
    });
  };

  useEffect(() => {
    if (data) {
      setForm(data);
    }
  }, [data]);

  const personalityTypes: string[] = useMemo(() => {
    return Object.values(tenantProfile?.personalities || {}) || [];
  }, [tenantProfile]);

  return (
    <Modal
      contentClassName="!px-0"
      title="Customizer"
      isOpen={isOpen}
      handleModal={onClose}
    >
      <div className="max-h-[calc(100vh-125px)] overflow-auto">
        <div className="px-5 flex flex-col md:flex-row items-center justify-between mt-2.5 gap-2">
          <div className="text-[#444] text-base font-normal w-full md:max-w-[60%]">
            Customize the HCP character you would like to practice with.{" "}
          </div>
          <Button
            variant="primary"
            className="w-full md:w-auto !px-6 !py-[14px] !bg-[#F8E9F1] hover:!bg-[#C83E1E] hover:!text-white !rounded-full text-lg font-medium !text-[#7C2928]"
            onClick={handleRandomize}
          >
            <RandomIcon />
            <span>Randomize</span>
          </Button>
        </div>
        <Separator />

        <div className="flex flex-col gap-4 px-5">
          <Heading
            title="Health Care Practitioner Type"
            subtitle="Select one"
          />
          <div className="items-center flex-wrap gap-2 flex flex-wrap">
            {practitionerTypes.map((type) => (
              <SelectButton
                title={type}
                key={type}
                onClick={() => handleChange(type, "practitionerType")}
                selected={form.practitionerType === type}
              />
            ))}
          </div>
        </div>
        <Separator />
        <div className="flex flex-col gap-4 px-5">
          <Heading title="Level of Objection" />
          <div>
            <SliderView
              value={form.objectionLevel}
              onChange={(value) => handleChange(value, "objectionLevel")}
            />
          </div>
        </div>
        <Separator />
        <div className="flex flex-col gap-4 px-5">
          <Heading title="Personality Type" subtitle="Select multiple" />
          <div className="items-center flex-wrap gap-2 flex flex-wrap">
            {personalityTypes.map((type) => (
              <SelectButton
                title={type}
                key={type}
                onClick={() => handleChange(type, "personalityType")}
                selected={
                  form.personalityType.findIndex(
                    (_type) => _type.toLowerCase() === type.toLowerCase()
                  ) > -1
                }
              />
            ))}
          </div>
        </div>
        <Separator />
        <div className="flex flex-col md:flex-row gap-4 items-center px-5">
          <div className="w-full flex-1 p-4 rounded-lg border border-neutral-200 flex-col justify-start items-start gap-4 flex">
            <Heading title="Time HCP has available to begin" />

            <div className="self-stretch justify-start items-start gap-2 flex">
              <div className="w-full p-2 rounded-2xl border border-pink-100 justify-between items-center flex">
                <button
                  type="button"
                  onClick={() => handleChange(form.beginTime - 1, "beginTime")}
                  disabled={form.beginTime === 0}
                  className="w-11 h-11 p-3.5 bg-[#F3E8ED] rounded-lg justify-center items-center gap-1 flex"
                >
                  <MinusIcon className="w-6 h-6 relative" />
                </button>
                <div className="flex items-center text-[#434343] text-base font-medium gap-1">
                  <input
                    type="text"
                    value={form.beginTime}
                    onChange={(e) => {
                      if (
                        !Number.isNaN(Number(e.target.value)) &&
                        Number(e.target.value) >= 0 &&
                        Number(e.target.value) <= 7
                      ) {
                        handleChange(Number(e.target.value), "beginTime");
                      }
                    }}
                    min={0.1}
                    max={7}
                    className="w-8 text-right !outline-none"
                  />
                  mins
                </div>
                <button
                  type="button"
                  disabled={form.beginTime >= 7}
                  onClick={() => handleChange(form.beginTime + 1, "beginTime")}
                  className="w-11 h-11 p-3.5 bg-[#F3E8ED] rounded-lg justify-center items-center gap-1 flex"
                >
                  <PlusIcon className="w-6 h-6 relative" />
                </button>
              </div>
            </div>
          </div>
          <div className="w-full p-4 flex-1 rounded-lg border border-neutral-200 flex-col justify-start items-start gap-4 flex">
            <Heading title="Product/Patient based:" />
            <div className="self-stretch justify-start items-start gap-2 flex">
              <div className="grow shrink basis-0 h-16 p-2 rounded-2xl border border-pink-100 justify-start items-center gap-2 flex">
                <SelectButton
                  title="Product"
                  className="flex-1"
                  onClick={() => handlePatientChange("Product")}
                  selected={form.isPatientBased === false}
                />
                <SelectButton
                  title="Patient"
                  className="flex-1"
                  onClick={() => handlePatientChange("Patient")}
                  selected={form.isPatientBased === true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-[26px] px-5">
          <Button
            variant="primary"
            className="px-7 !py-4 !text-lg md:w-[180px] !font-medium !rounded-full"
            onClick={() => onContinue(form)}
          >
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  );
}
