import React from "react";
import { api } from "../../../services/api";
import { ShowToast } from "../../../services/toast";
import { CallProcessingLoadingState } from "../../praxis-call/components/CallProcessingLoadingState";

export const PraxisCallContext = React.createContext({});

export function PraxisCallProvider({ children }) {
  const [callDetails, setCallDetails] = React.useState<{
    id?: string;
  }>({});
  const [loading, setLoading] = React.useState(false);
  const [callStatus, setCallStatus] = React.useState("idle"); // idle, calling, connected, ended
  const [report, setReport] = React.useState<any>({});
  const [fetchingReports, setFetchingReports] = React.useState(false);
  const [transcriberToken, setTranscriberToken] = React.useState<string | null>(
    null
  );

  const createTranscriberToken = async () => {
    const response = await api.getAssemblyAIToken();
    if (response?.data?.token) {
      setTranscriberToken(response?.data?.token);
    }
  };

  const fetchReports = async () => {
    try {
      setFetchingReports(true);
      const response = await api.getReports(callDetails?.id as string);
      setFetchingReports(false);
      if ([200, 201].includes(response.status)) {
        setReport(response.data);
      }
    } catch (error: any) {
      setFetchingReports(false);
      ShowToast({
        message:
          error?.response?.data?.message ||
          "An error occurred while fetching the reports",
        type: "error",
      });
    }
  };

  const onDisconnect = async () => {
    try {
      if (!callDetails?.id) {
        return;
      }
      setLoading(true);
      const response = await api.endCall(callDetails?.id);

      if ([200, 201].includes(response.status)) {
        await fetchReports();
        setLoading(false);
        setCallStatus("ended");
      } else {
        ShowToast({
          message: response?.data?.message || "An error occurred",
          type: "error",
        });
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      ShowToast({
        message:
          error?.response?.data?.message ||
          "An error occurred while ending the call",
        type: "error",
      });
      console.log(error);
    }
  };

  const values = {
    callDetails,
    setCallDetails,
    callStatus,
    report,
    setCallStatus,
    onDisconnect,
    transcriberToken,
    createTranscriberToken,
    fetchingReports,
  };
  return (
    <PraxisCallContext.Provider value={values}>
      {loading ? <CallProcessingLoadingState /> : children}
    </PraxisCallContext.Provider>
  );
}

export function usePraxisCall(): {
  callDetails: {
    id?: string;
  };
  setCallDetails: React.Dispatch<
    React.SetStateAction<{
      id?: string;
    }>
  >;
  callStatus: string;
  setCallStatus: React.Dispatch<React.SetStateAction<string>>;
  report: unknown;
  setReport: React.Dispatch<React.SetStateAction<unknown>>;
  onDisconnect: () => Promise<void>;
  fetchingReports: boolean;
  transcriberToken: string | null;
  createTranscriberToken: () => Promise<void>;
} {
  const context = React.useContext(PraxisCallContext);
  if (context === undefined) {
    throw new Error("usePraxisCall must be used within a PraxisCallProvider");
  }
  return context as {
    callDetails: {
      id?: string;
    };
    setCallDetails: React.Dispatch<
      React.SetStateAction<{
        id?: string;
      }>
    >;
    callStatus: string;
    fetchingReports: boolean;
    setCallStatus: React.Dispatch<React.SetStateAction<string>>;
    report: unknown;
    setReport: React.Dispatch<React.SetStateAction<unknown>>;
    onDisconnect: () => Promise<void>;
    transcriberToken: string | null;
    createTranscriberToken: () => Promise<void>;
  };
}
