import React from "react";
import passwordValidation from "../register/Register";
import Modal from "../../../components/Modal";
import { Password } from "../components/Password";
import { Button } from "../../../components/Button";
export function ChangePassword({
  onClose,
  isOpen,
  onSave,
  loading,
}: {
  onClose: (success?: boolean) => void;
  onSave: (data: any) => void;
  isOpen: boolean;
  loading?: boolean;
}) {
  const [disabled, setDisabled] = React.useState(true);
  const [isPasswordSame, setIsPasswordSame] = React.useState<string>("");
  const [errors, setErrors] = React.useState({} as any);
  const formRef = React.useRef<HTMLFormElement>(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // get the form data
    const formData = new FormData(formRef.current as HTMLFormElement);
    // convert the form data to an object
    const data = Object.fromEntries(formData);
    // log the results
    onSave({ password: data.password.toString() });
    return false;
  };
  const handleChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);
    if (
      e.target.name === "password" &&
      !passwordValidation(data.password.toString())
    ) {
      setErrors((prev) => ({
        ...prev,
        password:
          "Password should contain at least 8 characters, 1 letter, 1 number and 1 special character",
      }));
    } else {
      setErrors((prev) => ({ ...prev, password: "" }));
    }
    if (
      e.target.name === "confirmPassword" &&
      !passwordValidation(data.confirmPassword.toString())
    ) {
      setErrors((prev) => ({
        ...prev,
        confirmPassword:
          "Password should contain at least 8 characters, 1 letter, 1 number and 1 special character",
      }));
    } else {
      setErrors((prev) => ({ ...prev, confirmPassword: "" }));
    }
    if (
      data.password &&
      data.confirmPassword &&
      data.password === data.confirmPassword &&
      passwordValidation(data.password.toString()) &&
      passwordValidation(data.confirmPassword.toString())
    ) {
      setIsPasswordSame("");
      setDisabled(false);
    } else {
      if (
        data.password &&
        data.confirmPassword &&
        data.password !== data.confirmPassword
      ) {
        setIsPasswordSame("Password does not match");
      } else {
        setIsPasswordSame("");
      }
      setDisabled(true);
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal contentClassName="w-[500px]" isOpen={isOpen} handleModal={onClose}>
      <div className="flex flex-col w-full">
        <div className="flex justify-center items-center w-full">
          <form
            // onSubmit={handleSubmit}
            className="flex flex-col w-full"
            onChange={handleChange}
            ref={formRef}
          >
            <div className="rounded-lg flex flex-col">
              <div className="flex justify-between items-center">
                <div className="text-black text-[28px] font-bold leading-loose">
                  Change Password
                </div>
              </div>
              <span className="text-black/80 text-sm font-normal leading-normal ">
                Change your password below.
              </span>
              <div className="my-6 flex flex-col gap-5">
                <div className="flex flex-col gap-1.5">
                  <span className="text-black text-xs font-medium">
                    New Password
                  </span>
                  <Password
                    placeholder="Create a new password"
                    className="!p-[14px]"
                    name="password"
                  />
                  {errors.password && (
                    <div className="text-red-500 text-xs font-medium">
                      {errors.password}
                    </div>
                  )}
                </div>
                <div className="flex flex-col gap-1.5">
                  <span className="text-black text-xs font-medium">
                    Confirm Password
                  </span>
                  <Password
                    type="password"
                    placeholder="Re-enter the password"
                    className="!p-[14px]"
                    name="confirmPassword"
                  />

                  {isPasswordSame && (
                    <span className="text-red-500 text-xs font-medium">
                      {isPasswordSame}
                    </span>
                  )}
                </div>
              </div>
              <Button
                disabled={disabled || loading}
                loading={loading}
                className="!rounded-full w-full"
                variant="primary"
                onClick={handleSubmit}
              >
                Change
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
