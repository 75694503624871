import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import Routes from "./pages/routes";
import "./App.css";
import { useEffect } from "react";
import { api } from "./services/api";
import { getUser } from "./services/utils";
import useFetchS3Json from "./services/useFetchS3Json";
import { AppProvider } from "./services/app-context";

// Create a client
const queryClient = new QueryClient();

function App() {
  const user = getUser();

  useEffect(() => {
    return () => {
      speechSynthesis.cancel();
    };
  }, []);

  const makeLastActive = async (userId: string) => {
    try {
      await api.saveLastActive(userId);
    } catch (error) {}
  };

  useEffect(() => {
    try {
      if (user?.id) {
        makeLastActive(user?.id);
      }
    } catch (error) {}
    return () => {
      try {
        if (user?.id) {
          makeLastActive(user?.id);
        }
      } catch (error) {}
    };
  }, [user?.id]);

  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <Toaster />
        <div className="min-h-screen flex flex-col">
          <Routes />
        </div>
      </AppProvider>
    </QueryClientProvider>
  );
}

export default App;
