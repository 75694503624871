import React, { useEffect } from "react";
import { ShowToast } from "./toast";
import { getUser } from "./utils";
import { api } from "./api";
import { useApp } from "./app-context";

export const UserContext = React.createContext({});

export function UserProvider({ children }) {
  const { userSession } = useApp();
  const [userInfo, setUserInfo] = React.useState({});
  const [userInfoLoading, setUserInfoLoading] = React.useState(false);
  const user = getUser();
  const fetchUser = async (userId: string) => {
    try {
      setUserInfoLoading(true);
      const response = await api.getUserProfile(userId);
      setUserInfoLoading(false);
      setUserInfo({
        ...response,
      });
    } catch (error) {
      ShowToast({
        message: "Something went wrong while fetching user info",
        type: "error",
      });
    }
  };
  const values = {
    userInfo,
    setUserInfo,
    fetchUser,
    loading: userInfoLoading,
  };
  useEffect(() => {
    if (userSession?.idToken) {
      setUserInfo({
        ...(userSession?.idToken?.payload || {}),
      });
    }
  }, [userSession]);
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
}

export function useUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}
