import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { ReactComponent as Logo } from "../../assets/icons/animation-logo.svg";
import { ActionButtons } from "./ActionButtons";
import { usePraxisCall } from "../praxis-play/context/praxiscall-context";
import { AudioPlayer } from "./components/AudioPlayer";
import ClockTimer from "./components/ClockTimer";
import { useUserSpeakingDetector } from "./components/userSpeakingDetector";
import { useAssemblyAI } from "./services/useAssemblyAI";
import * as RecordRTC from "recordrtc";
import { PraxisLoading } from "./PraxisLoading";
import { api } from "../../services/api";
import { ShowToast } from "../../services/toast";
import toast from "react-hot-toast";

const audioPlayer = new AudioPlayer();

export function PraxisCall() {
  const recorderRef = useRef<any>(null);
  const { callDetails, onDisconnect, transcriberToken } = usePraxisCall();
  const [isConnected, setIsConnected] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const isUploadingRef = useRef(false);
  const isConnectedRef = useRef(false);
  const { realtimeTranscriber } = useAssemblyAI({
    token: transcriberToken,
    onConnect: () => {
      setIsConnected(true);
      isConnectedRef.current = true;
    },
    onTranscript: (transcript) => {
      if (
        !isUploadingRef.current &&
        !audioPlayer.isPlaying &&
        callDetails?.id
      ) {
        setIsUploading(true);
        isUploadingRef.current = true;
        api
          .sendFullTextResponse(callDetails.id, {
            response: transcript.text,
            isUserMessage: true,
          })
          .catch((error) => {
            console.log("Error uploading transcript:", error);
          });
        api
          .chatStream({ query: transcript.text, callId: callDetails.id })
          .then(async (response) => {
            setIsUploading(false);
            isUploadingRef.current = false;
            if (response) {
              if (callDetails?.id) {
                api.sendFullTextResponse(callDetails.id, {
                  response: "",
                  isUserMessage: false,
                });
              }
              monitorVoiceIntensity();
              const binaryString = atob(JSON.parse(response));
              const len = binaryString.length;
              const bytes = new Uint8Array(len);
              for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
              }
              try {
                audioPlayer.enqueueBuffer(bytes.buffer);
              } catch (error) {
                console.error("Failed to decode audio:", error);
              }
            }
          })
          .catch((error) => {
            console.log("Error uploading transcript:", error);
            setIsUploading(false);
            isUploadingRef.current = false;
          });
      }
    },
  });
  const [voiceIntensity, setVoiceIntensity] = useState(0);
  const [userVoiceIntensity, setUserVoiceIntensity] = useState(0);

  const audioContextRef = useRef<any>(null);
  const analyserRef = useRef<any>(null);
  const microphoneRef = useRef<any>(null);
  const volumeMonitorIntervalRef = useRef<any>(null);

  const streamRef = useRef<any>();
  const harkRef = useRef<any>();

  const createRecorder = useCallback(() => {
    if (!realtimeTranscriber || !streamRef.current) return;
    if (recorderRef.current) return;

    recorderRef.current = new RecordRTC(streamRef.current, {
      type: "audio",
      mimeType: "audio/webm;codecs=pcm",
      recorderType: RecordRTC.StereoAudioRecorder,
      timeSlice: 250,
      desiredSampRate: 16000,
      numberOfAudioChannels: 1,
      bufferSize: 4096,
      audioBitsPerSecond: 128000,
      ondataavailable: (blob) => {
        if (isConnectedRef.current) {
          try {
            realtimeTranscriber?.sendAudio(blob);
          } catch (e) {}
        }
      },
    });

    recorderRef.current?.startRecording();
  }, [realtimeTranscriber]);

  useEffect(() => {
    if (isConnected && realtimeTranscriber?.sendAudio) {
      createRecorder();
    }
  }, [isConnected, realtimeTranscriber, createRecorder]);

  const { isSpeaking: isUserSpeaking, destroy } = useUserSpeakingDetector({
    stream: streamRef.current,
  });

  useEffect(() => {
    const setupAudioContext = async () => {
      try {
        audioPlayer.ensureAudioContext();
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        streamRef.current = stream;
        createRecorder();
        audioContextRef.current = new (window.AudioContext ||
          (window as any).webkitAudioContext)();
        microphoneRef.current =
          audioContextRef.current.createMediaStreamSource(stream);
        analyserRef.current = audioContextRef.current.createAnalyser();
        microphoneRef.current.connect(analyserRef.current);
        analyserRef.current.fftSize = 256;

        // Buffer to store frequency data
        const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);

        const captureVoiceIntensity = () => {
          analyserRef.current.getByteFrequencyData(dataArray);
          let sum = 0;
          dataArray.forEach((value) => (sum += value));
          const average = sum / dataArray.length;
          setUserVoiceIntensity(average);
          requestAnimationFrame(captureVoiceIntensity);
        };

        captureVoiceIntensity();
      } catch (error: any) {
        if (error.name === "NotAllowedError") {
          toast.dismiss();
          ShowToast({
            message:
              "Please allow microphone access to continue. Without microphone you won't be able to proceed.",
            type: "error",
          });
        } else {
          console.error("Error setting up audio context:", error);
        }
      }
    };

    setupAudioContext();

    return () => {
      if (streamRef.current) {
        streamRef.current
          .getTracks()
          .forEach((track: MediaStreamTrack) => track.stop());
      }
    };
  }, [createRecorder]);

  const handleClose = async () => {
    destroy();
    if (audioPlayer.source) {
      audioPlayer.source.disconnect();
      audioPlayer.source.stop();
    }
    audioPlayer.audioContext.suspend();
    if (harkRef.current) harkRef.current.stop();
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
    }
    streamRef.current = null;
    await onDisconnect();
  };

  const monitorVoiceIntensity = useCallback(() => {
    const captureVoiceIntensity = () => {
      if (!audioPlayer.isPlaying) {
        setVoiceIntensity(0);
        return;
      }
      const dataArray = new Uint8Array(audioPlayer.analyser.frequencyBinCount);

      audioPlayer.analyser.getByteFrequencyData(dataArray);
      let sum = 0;
      audioPlayer.dataArray.forEach((value) => (sum += value));
      const average = sum / dataArray.length;
      setVoiceIntensity(Math.min(average, 100) * 100000);
      // requestAnimationFrame(captureVoiceIntensity);
    };

    volumeMonitorIntervalRef.current = setInterval(captureVoiceIntensity, 100);
    captureVoiceIntensity();
  }, []);

  const stopMonitoringVoiceIntensity = useCallback(() => {
    if (volumeMonitorIntervalRef.current) {
      clearInterval(volumeMonitorIntervalRef.current);
      setUserVoiceIntensity(0);
      setVoiceIntensity(0);
      volumeMonitorIntervalRef.current = null;
    }
  }, []);

  useEffect(() => {
    document.addEventListener("playbackFinished", stopMonitoringVoiceIntensity);
    return () => {
      document.removeEventListener(
        "playbackFinished",
        stopMonitoringVoiceIntensity
      );
    };
  }, [stopMonitoringVoiceIntensity]);

  return (
    <div className="fixed z-[9] top-0 bottom-0 left-0 right-0">
      <div className="w-full h-full left-0 top-0 absolute bg-zinc-300 rounded-xl" />
      <div className="w-60 h-full left-[1341.79px] top-[-15.71px] absolute bg-gradient-to-b from-neutral-300 via-gray-300 to-gray-300" />
      <div className="w-64 h-full left-[-8.16px] top-[-34.27px] absolute bg-gradient-to-b from-neutral-300 via-gray-300 to-gray-300" />
      <div className="w-full h-full left-0 top-0 absolute bg-callScreen rounded-xl" />
      <div className="absolute top-0 bottom-0 h-full w-full">
        <button
          type="button"
          onClick={handleClose}
          className="w-10 h-10 p-2 bg-white rounded-3xl justify-start items-start gap-2 flex absolute right-3.5 top-3.5"
        >
          <CloseIcon className="w-6 h-6" />
        </button>
        {isConnected && <ClockTimer />}
        <ActionButtons onDisconnect={handleClose} />
        <div
          className="ai-interaction-container"
          style={{
            background:
              "linear-gradient(180deg, #DEDCDB 0%, #DCDAD9 20%, #DCD8D7 42.5%, #D8D6D4 52.5%, #D6D2D1 60%, #D5D1CF 80%, #D3CECC 100%)",
          }}
        >
          <div className="relative">
            <div
              className={`ai-circle`}
              style={{
                // increase drop shadow area when speaking
                filter:
                  "drop-shadow(0px 0px 3.039px #E79AAF) drop-shadow(0px 0px 6.077px #E79AAF) drop-shadow(0px 0px 24px #7F2F44)",
                backgroundColor: "#F0ECEC",
                transform: `scale(${voiceIntensity / 100000 + 1})`,
                boxShadow: voiceIntensity
                  ? `0px 0px 15.216px 9.47px rgba(173, 93, 57, 0.27) inset, 0 0 0 ${
                      voiceIntensity < 20
                        ? "0"
                        : voiceIntensity > 50
                        ? 50
                        : voiceIntensity
                    }px rgba(173, 93, 57, ${Math.min(
                      0.27,
                      voiceIntensity / 50 > 0.75 ? 0.75 : voiceIntensity / 50
                    )})`
                  : "0px 0px 15.216px 9.47px rgba(173, 93, 57, 0.27) inset",
                // slow down scaling and box shadow animation
                transition: "transform 0.01s ease, box-shadow 0.2s ease",
              }}
            >
              <div className="ai-logo ">
                <Logo />
              </div>
            </div>

            {isUploading && (
              <div className="rings absolute top-0 left-0 w-full h-full">
                <div className="ring-layer"></div>
                <div className="ring-layer"></div>
                <div className="ring-layer"></div>
                <div className="ring-layer"></div>
                <div className="ring-layer"></div>
                <div className="ring-layer"></div>
                <div className="ring-layer"></div>
              </div>
            )}

            {/* {!audioPlayer.isPlaying && (
              <>
                {Array.from({ length: 6 }).map((_, index) => (
                  <div
                    key={index}
                    className={`pulse-ring`}
                    style={{
                      borderColor: "rgba(255, 142, 105, 0.2)",
                    }}
                  ></div>
                ))}
              </>
            )} */}
          </div>
        </div>
      </div>
      {!isConnected && <PraxisLoading />}
    </div>
  );
}
