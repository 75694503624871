import { useState, useEffect } from "react";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";

const useFetchS3Json = (
  bucket,
  key,
  accessKeyId,
  secretAccessKey,
  region = "your-region"
) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchJsonFromS3 = async () => {
      const s3 = new S3Client({
        region: region,
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
        },
      });

      try {
        const command = new GetObjectCommand({
          Bucket: bucket,
          Key: key,
        });

        const response = await s3.send(command);
        const bodyContents: any = await streamToString(response.Body);
        if (bodyContents) {
          // Convert JSON string to a JavaScript object
          const jsonObject = JSON.parse(bodyContents);
          setData(jsonObject); // Update the data state with the JSON object
        } else {
          setData(null);
        }
      } catch (err: any) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchJsonFromS3();
  }, [bucket, key, accessKeyId, secretAccessKey, region]);

  // Helper function to convert stream to string
  const streamToString = async (stream) => {
    const data = await stream.transformToString();
    return data || null;
  };

  return { data, loading, error };
};

export default useFetchS3Json;
