import QRCode from "react-qr-code";
import { Button } from "../../../components/Button";
import Modal from "../../../components/Modal";
import { TextInput } from "../../../components/TextInput";
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";

export function MFASetup({
  isOpen,
  onClose,
  authCode,
  setAuthCode,
  verifyTotp,
  mfaSecret,
  loading,
  onSetupMFA,
  tenantProfile,
}: {
  isOpen: boolean;
  onClose: () => void;
  authCode: string;
  setAuthCode: (code: string) => void;
  verifyTotp: (code: string) => void;
  mfaSecret: { code: string; email: string };
  loading?: boolean;
  onSetupMFA: () => void;
  tenantProfile: any;
}) {
  const [qrCodeData, setQrCodeData] = useState(null);

  // const generateQrCode = (totpSecret) => {
  //   const issuer = "AWSCognito";
  //   const username = mfaSecret?.email; // User email or username

  //   // Generate the otpauth URL
  //   const otpauthUrl = `otpauth://totp/${issuer}:${username}?secret=${totpSecret}&issuer=${issuer}`;

  //   console.log(QRCode.toDataURL);
  //   // Generate the QR Code
  //   const qrCodeDataUrl = QRCode.toBase64(otpauthUrl);

  //   setQrCodeData(qrCodeDataUrl); // Set the QR code data URL to state
  // };

  // useEffect(() => {
  //   if (mfaSecret?.code) {
  //     generateQrCode(mfaSecret?.code);
  //   }
  // }, [mfaSecret?.code]);

  useEffect(() => {
    if (authCode.length === 6) {
      verifyTotp(authCode);
    }
  }, [authCode]);

  return (
    <Modal contentClassName="w-[450px]" isOpen={isOpen} handleModal={onClose}>
      <div className="text-2xl font-bold">Multi-Factor Authentication</div>
      <div>
        {mfaSecret?.code ? (
          <div className="py-4">
            <p className="text-sm mb-2">
              Scan the QR code with your authenticator app. Install either
              Google Authenticator, Duo Mobile, or Authy app on your mobile
              device.
            </p>
            <p className="text-base">
              Use your virtual MFA app or your device's camera to scan the QR
              code.
            </p>
            <div className="w-full flex justify-center">
              <div className=" mt-4 p-1 border border-black/20 rounded-md w-40 h-40">
                <QRCode
                  value={`otpauth://totp/Praxispro-${tenantProfile?.user_pool_name}:${mfaSecret?.email}?secret=${mfaSecret?.code}&issuer=Praxispro-${tenantProfile?.user_pool_name}`}
                  className="w-full h-full"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="py-4 text-sm">
            Open your authenticator app Google Authenticator, Duo Mobile, or
            Authy app on your mobile device where you have setup MFA before. Get
            the code from the app and enter it below.
          </div>
        )}

        <div className="flex items-center justify-center gap-2">
          <div className="my-2 flex-1 flex flex-col items-center gap-1.5">
            <label htmlFor="password" className="text-sm text-black/80">
              Enter the 6 digit code from your authenticator app
            </label>

            <div className="remove-arrow">
              <OtpInput
                value={authCode}
                onChange={setAuthCode}
                numInputs={6}
                renderSeparator={() => <div className="w-[0px] h-px"></div>}
                shouldAutoFocus={true}
                inputType="number"
                renderInput={(props, index) => (
                  <input
                    {...props}
                    className={`!w-[55px] h-16 px-3.5 py-3.5 bg-white border-y border-l ${
                      index === 5 ? "border-r rounded-r" : ""
                    } ${
                      index === 0 ? "rounded-l" : ""
                    } border-gray-200 items-center flex text-sm`}
                  />
                )}
              />
            </div>
          </div>
        </div>

        {/* {!mfaSecret?.code && (
          <div>
            <div className="flex items-center text-sm font-medium gap-2">
              <p>Do you want to setup the authenticator app?</p>
              <button
                type="button"
                onClick={onSetupMFA}
                className="text-primary"
              >
                Setup here
              </button>
            </div>
          </div>
        )} */}
        <div className="flex flex-col justify-end mt-4">
          <Button
            disabled={loading || !authCode || authCode.length !== 6}
            loading={loading}
            className="!rounded-full w-full"
            variant="primary"
            onClick={() => verifyTotp(authCode)}
          >
            Verify
          </Button>
        </div>
      </div>
    </Modal>
  );
}
