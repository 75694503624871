import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Sidebar } from "../components/Sidebar";
import { useContext, useEffect, useState } from "react";
import { getUser } from "../services/utils";
import { UserProvider, useUser } from "../services/user-context";
import { PraxisCallProvider } from "./praxis-play/context/praxiscall-context";
import { MobileHeaderMenu } from "../components/Sidebar/MobileSidebar";
import { ReactComponent as PraxisProIcon } from "../assets/icons/white-logo.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/x.svg";
import { ReactComponent as HamburgerIcon } from "../assets/icons/hamburger.svg";
import { EditProfileModal } from "./auth/profile/EditProfile";
import { AppContext, useApp } from "../services/app-context";
import { IS_USE_BE } from "../env";

function MainApp() {
  const user = getUser();
  const { userInfo } = useUser() as any;
  const { logout } = useApp();
  const [expand, setExpand] = useState(false);
  const [edit, setEdit] = useState(false);

  const handleLogout = async () => {
    logout();
  };

  const handleEdit = () => {
    setEdit(true);
  };

  return (
    <div className="flex-1 flex flex-col lg:flex-row">
      <Sidebar user={{ ...user, ...userInfo }} onEdit={handleEdit} />
      <div className="fixed lg:hidden z-[5] top-0 left-0 right-0 px-4 lg:pl-10 lg:pr-5 py-[18px] bg-[#7C2928] border-gray-200 border-b border-opacity-10 backdrop-blur-3xl justify-between items-center flex">
        <PraxisProIcon />
        <div className="justify-end items-center gap-3 flex flex-1">
          <button
            type="button"
            className="lg:hidden"
            onClick={() => setExpand(!expand)}
          >
            {expand ? (
              <CloseIcon className="h-8 w-8 [&_path]:stroke-white" />
            ) : (
              <HamburgerIcon className="h-8 w-8 [&_path]:stroke-white" />
            )}
          </button>
        </div>
      </div>
      <div className="lg:hidden">
        {expand && (
          <MobileHeaderMenu
            onClose={() => setExpand(false)}
            userInfo={{ ...user, ...userInfo }}
            onLogout={handleLogout}
            onEdit={() => {
              // setOpenDropdown(false);
              // setIsProfileOpen(true);
              setEdit(true);
            }}
          />
        )}
      </div>
      <div className="flex-1 max-h-screen overflow-auto bg-background">
        <Outlet />
      </div>
      {edit && (
        <EditProfileModal isOpen={edit} onClose={() => setEdit(false)} />
      )}
    </div>
  );
}

export function Main() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { userSession, userSessionLoading, tenantLoading } = useApp();

  useEffect(() => {
    if (IS_USE_BE) {
      const token = localStorage.getItem("token");
      const userData = JSON.parse(localStorage.getItem("user") || "{}");
      if (!token || !userData?.id) {
        navigate("/login", {
          state: { from: `${location.pathname}${location.search}` },
        });
        setIsLoggedIn(false);
      } else {
        setIsLoggedIn(true);
        if (location.pathname === "/") {
          navigate("/praxisplay");
        }
      }
    } else {
      if (userSessionLoading) return;
      if (tenantLoading) return;
      if (!userSession) {
        console.log("userSession", userSession);
        navigate("/login");
        setIsLoggedIn(false);
      } else {
        setIsLoggedIn(true);
        if (location.pathname === "/") {
          navigate("/praxisplay");
        }
      }
    }
  }, [userSession, userSessionLoading, tenantLoading]);

  if (!isLoggedIn) return null;

  return (
    <UserProvider>
      <PraxisCallProvider>
        <MainApp />
      </PraxisCallProvider>
    </UserProvider>
  );
}
