export const processingMessages = [
  "Hmm, that's an interesting question. Let me think for a moment.",
  "Give me a second to gather my thoughts on that.",
  "I'm considering all the possibilities. Just a moment.",
  "That's a tough one. Let me chew on it for a bit.",
  "I'm thinking about that. Just a moment.",
  "Hmm, let me run through some scenarios before I respond.",
];

export enum PractitionerTypes {
  "Internal_Medicine" = "Internal Medicine",
  "Family_Medicine" = "Family Medicine",
  "Gastroenterology" = "Gastroenterology",
  "Pulmonology" = "Pulmonology",
}

export enum PersonalityTypes {
  "Clinical_Data_Driven" = "Clinical Data Driven",
  "Skeptical" = "Skeptical",
  "Story_Telling" = "Story-Telling",
  "Agreeable" = "Agreeable",
  "Busy" = "Busy",
  "To_The_Point" = "To-The-Point",
}
// "Data_Driven"= "Data Driven",

export enum ObjectionLevels {
  "L" = "L",
  "M" = "M",
  "H" = "H",
}
