import { useEffect, useRef } from "react";
import { RealtimeTranscriber } from "assemblyai";

export const useAssemblyAI = ({
  token,
  onConnect,
  onTranscript,
}: {
  token?: string | null;
  onConnect: (realtimeTranscriber: any) => void;
  onTranscript: (transcript: any) => void;
}) => {
  /** @type {React.MutableRefObject<RealtimeTranscriber>} */
  const realtimeTranscriber = useRef<RealtimeTranscriber | null>(null);

  const connect = async () => {
    if (realtimeTranscriber.current) {
      await realtimeTranscriber.current.connect();
    }
  };

  useEffect(() => {
    if (token && !realtimeTranscriber.current) {
      realtimeTranscriber.current = new RealtimeTranscriber({
        token,
        sampleRate: 16_000,
        disablePartialTranscripts: true,
        endUtteranceSilenceThreshold: 2500,
      });
      realtimeTranscriber.current.on("open", ({ sessionId, expiresAt }) => {
        onConnect(realtimeTranscriber);
      });
      realtimeTranscriber.current.on("close", (code: number, reason: string) =>
        console.log("Closed", code, reason)
      );
      realtimeTranscriber.current.on("transcript", (transcript: any) => {
        onTranscript(transcript);
      });
      realtimeTranscriber.current.on("transcript.final", (transcript: any) => {
        console.log("blob Final transcript:", transcript);
      });
      realtimeTranscriber.current.on("error", (error: Error) =>
        console.error("Error", error)
      );
      connect();
    }
  }, [token]);

  return {
    realtimeTranscriber: realtimeTranscriber.current,
  };
};
