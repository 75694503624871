import { CoachingDetails } from "./components/CoachingDetails";
import { Productivity } from "./components/Productivity";
import { ReportPanelView } from "./components/ReportPanelView";
import { ObservationCompliance } from "./components/ObservationCompliance";

export function FCRReport() {
  return (
    <>
      <div className="text-black text-2xl font-bold">Field Coaching Report</div>
      <div className="text-black text-lg mt-3 mb-8">
        This Field Coaching Report is to be acknowledged by end of business on
        2/28/2024.
      </div>
      <div className="border-b border-[#7C292838]" />
      <div className="text-primary my-7 text-2xl font-bold">
        Survey target Details
      </div>
      <div className="mb-5 flex gap-5 flex-col md:flex-row">
        <CoachingDetails />
        <Productivity />
      </div>
      <div className="flex flex-col gap-5">
        <ReportPanelView
          title="Selling Framework"
          subTitle="Develop Influence; Prepare"
        >
          <div className="text-[#242424] text-sm text-light">
            Day: on XX/XX. Today, I observed you “Prepare” & "Develop Influence”
            in relation to the Avanir Sales Framework For "Preparing" today, we
            discussed an opportunity with an HCP surrounding 2 questions from
            the Specialty AMP Model 2.0: See AMP Comments. You did an excellent
            job of “Developing Influence” with every HCP today by leveraging the
            “Asking with Care” & “ A Proper Diagnosis Starts with You” sales
            aids. By explaining the importance of adjectives and phrases
            patients/caregivers may use when describing PBA symptoms to HCPs,
            and how HCPs need to be intune to these adjectives and phrases to
            help distinguish PBA from Depression and the possibility of a
            differential diagnosis. By making reference to the involuntary,
            sudden, frequent, exaggerated, and incongruent hallmark signs of PBA
            episodes allowed you to gain agreement with all of the HCPs of the
            need to dig a little deeper when discussing the possibility of
            patients presenting w/ PBA in lieu of Depression or other
            Mood/Behavior characteristics
          </div>
        </ReportPanelView>
        <ReportPanelView
          title="Account Management Process"
          subTitle="Develop Influence; Prepare"
        >
          <div className="text-[#242424] text-sm text-light">
            As we prioritize "Intentionality" on every call with HCPs/Offices, I
            ask that you select 1 to 2 questions from the Specialty AMP Model to
            engage your HCPs/Offices with on every call. For today, you decided
            to ask/uncover: Which specific underlying neurological diseases has
            the HCP identified PBA in? HCP X stated he/she sees Parkinson’s
            patients. He/She also said he/she doesn’t see many TBI patients b/c
            the military base has a TBI clinic/provider. He/She also said he/she
            doesn’t see PBA in stroke patients very often.b.HCP Y sees a lot of
            MS patients from other HCPs who have been misdiagnosed w/ bipolar
            when in fact they have PBA. How does the HCP Assess CNS Symptoms?
            HCP W relies on patient, clinician, care-giver dialogue to dig
            deeper into underlying issues. He/She also said he/she knows PBA
            when he/she "sees" it.“sees” it. HCP Y uses both the CNS-LS and
            relies on patient, clinician, care-giver dialogue to dig deeper into
            underlying issues.
          </div>
          <div className="text-[#242424] text-sm text-light mt-6">
            As a result of these 2 questions, you gained valuable information to
            build upon for the next call. More importantly, this breaks the
            cycle of "service calls." This will help stimulate more impactful
            and hopefully more productive calls with the HCP and Staff Commit to
            yourself you will learn "something new" about your customers/offices
            on every call. This will help you in increasing your AMP mapping of
            the office and help you understand your customers on a deeper level.
            Sophia, I witnessed you asking appropriate, informational questions
            of Office staff, MAs, Office Managers, and HCPs. You positioned and
            tailored your questions based upon the role of each person you
            interacted with. Since each office is operating differently as a
            result of COVID, continue to incorporate this strategy to help guide
            and tailor your approach and interactions with your accounts.
            Leverage the knowledge you uncover as you progress through the AMP
            process with each individual office.
          </div>
        </ReportPanelView>
        <ObservationCompliance />
      </div>
    </>
  );
}
