import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/Button";
import { TextInput } from "../../../components/TextInput";
import React, { useEffect, useState } from "react";
import { ReactComponent as AlertIcon } from "../../../assets/icons/Alert.svg";
import { Password } from "../components/Password";
import passwordValidation from "../register/Register";
import { ChangePassword } from "./ChangePassword";
import { ReactComponent as PraxisProIcon } from "../../../assets/icons/logo-white-bg.svg";
import { ReactComponent as StatisticsIcon } from "../../../assets/icons/statistics.svg";
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import { useApp } from "../../../services/app-context";

import { MFASetup } from "./MFASetup";
import { ShowToast } from "../../../services/toast";
import { IS_USE_BE } from "../../../env";
import { api } from "../../../services/api";
import { setToken, setUser } from "../../../services/utils";

export function Login() {
  const navigate = useNavigate();
  const { tenantProfile, cognitoUserRef, fetchUserSession } = useApp();
  const [errors, setErrors] = React.useState({} as any);
  const [disabled, setDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>("");
  const [showChangePassword, setShowChangePassword] = useState<any>(false);
  const [mfaSecret, setMfaSecret] = useState<any>();
  const [authCode, setAuthCode] = useState<string>("");
  const [showOtp, setShowOtp] = useState<{ isOpen: boolean; email: string }>({
    isOpen: false,
    email: "",
  });
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);

  const verifySoftwareToken = (authCode) => {
    if (authCode) {
      cognitoUserRef.current.verifySoftwareToken(authCode, "My TOTP device", {
        onSuccess: (result) => {
          setVerificationLoading(false);
          if (result?.accessToken) {
            navigate("/praxisplay");
            setShowOtp({ isOpen: false, email: "" });
            setMfaSecret(null);
          }
        },
        onFailure: (err) => {
          console.log(err);
          ShowToast({
            message: err.message || "Something went wrong",
            type: "error",
          });
          setVerificationLoading(false);
        },
      });
    }
  };

  const verifyTotp = (authCode) => {
    if (authCode) {
      setVerificationLoading(true);
      cognitoUserRef.current.sendMFACode(
        authCode,
        {
          onSuccess: (result) => {
            setVerificationLoading(false);
            if (result?.accessToken) {
              fetchUserSession();
              setToken(result?.accessToken?.jwtToken);
              navigate("/praxisplay");
              setShowOtp({ isOpen: false, email: "" });
              setMfaSecret(null);
            }
          },
          onFailure: (err) => {
            if (err.code === "NotAuthorizedException") {
              setShowOtp({ isOpen: false, email: showOtp.email });
              setLoading(false);
            }
            ShowToast({
              message: err.message || "Something went wrong",
              type: "error",
            });
            setVerificationLoading(false);
          },
        },
        "SOFTWARE_TOKEN_MFA"
      );
    }
  };

  const handleSetupMFA = (email: string) => {
    if (cognitoUserRef.current.associateSoftwareToken) {
      cognitoUserRef.current.associateSoftwareToken({
        onFailure: (error) => {
          console.error(error);
        },
        associateSecretCode: (code) => {
          setShowOtp({ isOpen: true, email: email });
          setMfaSecret({ code, email });
        },
      });
    }
  };

  const handlePasswordChange =
    (userAttributes: any) =>
    ({ password }: any) => {
      const { email, ...rest } = userAttributes;
      const payload = {
        ...rest,
      };
      console.log(payload);
      setChangePasswordLoading(true);
      cognitoUserRef.current.completeNewPasswordChallenge(
        password,
        {
          family_name: rest.family_name,
          given_name: rest.given_name,
        },
        {
          onSuccess: (result) => {
            setChangePasswordLoading(false);
          },
          onFailure: (err) => {
            console.log(err);
            setChangePasswordLoading(false);
          },
          totpRequired: () => {
            setShowOtp({ isOpen: true, email: email });
            setChangePasswordLoading(false);
          },
          mfaSetup: function () {
            cognitoUserRef.current.associateSoftwareToken({
              onFailure: (error) => {
                setChangePasswordLoading(false);
              },
              associateSecretCode: (code) => {
                setShowChangePassword(false);
                setChangePasswordLoading(false);
                setShowOtp({ isOpen: true, email });
                setMfaSecret({ code, email });
              },
            });
          },
        }
      );
    };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // get the form data
    const formData = new FormData(e.currentTarget);
    // convert the form data to an object
    const data = Object.fromEntries(formData);
    // log the results
    setError("");
    setLoading(true);
    try {
      if (IS_USE_BE) {
        const response = await api.login({
          email: data.email,
          password: data.password,
        });
        if (response.status === 401) {
          setError("Invalid credentials");
          setLoading(false);
          return;
        }
        const result = response.data;
        if (result?.accessToken) {
          setToken(result?.accessToken);
          if (result?.id) {
            const userResponse = await api.getUserProfile(result?.id);
            setUser({ ...userResponse });
          }

          if (result?.loginState === "Change Password") {
            setShowChangePassword(result);
          } else {
            navigate("/praxisplay");
          }
        }
        setLoading(false);
      } else {
        var poolData = {
          UserPoolId: tenantProfile.user_pool_id, // Your user pool id here
          ClientId: tenantProfile.user_pool_client_id, // Your client id here
        };
        var userPool = new CognitoUserPool(poolData);

        const authenticationData = {
          Username: data.email as string,
          Password: data.password as string,
        };
        const authenticationDetails = new AuthenticationDetails(
          authenticationData
        );

        const userData = {
          Username: data.email as string,
          Pool: userPool,
        };
        cognitoUserRef.current = new CognitoUser(userData);

        cognitoUserRef.current.authenticateUser(authenticationDetails, {
          onSuccess: function (result) {
            console.log(result);
          },

          onFailure: function (err) {
            setLoading(false);
            setError(err.message);
            // alert(err.message || JSON.stringify(err));
          },
          totpRequired: () => {
            setShowOtp({ isOpen: true, email: data.email as string });
          },
          mfaSetup: function (challengeName, challengeParameters) {
            cognitoUserRef.current.associateSoftwareToken({
              onFailure: (error) => {
                console.error(error);
              },
              associateSecretCode: (code) => {
                setShowOtp({ isOpen: true, email: data.email as string });
                setMfaSecret({ code, email: data.email });
              },
            });
          },
          newPasswordRequired: function (userAttributes, requiredAttributes) {
            // User was signed up by an admin and must provide new
            // password and required attributes, if any, to complete
            // authentication.

            // the api doesn't accept this field back
            setLoading(false);
            delete userAttributes.email_verified;
            const { email, ...rest } = userAttributes;
            // // store userAttributes on global variable
            // sessionUserAttributes = userAttributes;
            setShowChangePassword(userAttributes);
          },
        });
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
      if (error?.response?.status === 401) {
        setError("Invalid email or password");
        return;
      }
      setError("Something went wrong");
      return false;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);
    if (
      e.target.name === "password" &&
      !passwordValidation(data.password.toString())
    ) {
      setErrors((prev) => ({
        ...prev,
        password: "Invalid password",
      }));
    } else {
      setErrors((prev) => ({ ...prev, password: "" }));
    }
    if (
      data.email &&
      data.password &&
      passwordValidation(data.password.toString())
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleChangePasswordClose = (success?: boolean) => {
    setShowChangePassword(false);
    if (success) {
      navigate("/praxisplay");
    }
  };

  useEffect(() => {
    if (tenantProfile) {
      var poolData = {
        UserPoolId: tenantProfile.user_pool_id, // Your user pool id here
        ClientId: tenantProfile.user_pool_client_id, // Your client id here
      };
      var userPool = new CognitoUserPool(poolData);
      var cognitoUser = userPool.getCurrentUser();
      if (cognitoUser != null) {
        cognitoUser.getSession(function (err, session) {
          if (err) {
            alert(err.message || JSON.stringify(err));
            return;
          }

          // Instantiate aws sdk service objects now that the credentials have been updated.
          // example: var s3 = new AWS.S3();
        });
      }
    }
  }, [tenantProfile]);

  return (
    <div className="h-screen">
      <div className="flex items-center p-3.5 h-[100vh]">
        <div
          className="flex-1 h-[calc(100vh-28px)] relative hidden lg:flex flex-col rounded-[23px] items-center justify-center"
          style={{
            backgroundSize: "cover",
            background:
              "linear-gradient(131.57deg, #6E1336 2.11%, #CD3B1A 88.06%)",
          }}
        >
          <PraxisProIcon className="absolute top-[100px] tall:!top-[80px]" />
          <div className="flex-1 w-[calc(100%-150px)] max-h-[calc(100%-275px)] mx-auto gap-16 flex flex-col items-center justify-center">
            <StatisticsIcon className="z-[2] w-full h-auto" />
          </div>
        </div>
        <div className="flex-1 h-[100vh] lg:h-auto flex flex-col items-center justify-center overflow-auto px-4 md:px-0">
          <div className="bg-white tall2:min-h-[calc(100vh-75px)] py-8 md:p-8 lg:w-full lg:max-w-[80%] rounded-[16px]">
            <h1 className="text-3xl font-bold">Sign In</h1>
            {/* <div className="flex items-center gap-1 mt-3 text-sm md:text-lg">
                <span className="text-black">Don't have an account?</span>
                <a href="/" className="text-primaryLink font-semibold">
                  Create Account
                </a>
              </div> */}
            {error && (
              <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                <AlertIcon /> {error}
              </div>
            )}

            <div className="border-b border-[#DDD] w-full my-7"></div>
            <form
              className="mt-8"
              onSubmit={handleSubmit}
              onChange={handleChange}
            >
              <div className="mb-8 flex flex-col gap-1.5">
                <label htmlFor="email" className="text-sm text-black/80">
                  Email
                </label>
                <TextInput
                  type="email"
                  name="email"
                  placeholder="Enter your email address"
                />
              </div>
              <div className=" flex flex-col gap-1.5">
                <label htmlFor="password" className="text-sm text-black/80">
                  Password
                </label>
                <Password
                  type="password"
                  name="password"
                  placeholder="Enter a password"
                />
                {errors.password && (
                  <div className="text-red-500 text-xs font-medium">
                    {errors.password}
                  </div>
                )}
              </div>
              <div className="flex justify-end mt-[14px]">
                <a
                  href="/forgot-password"
                  className="text-black/80 text-sm font-medium"
                >
                  Forgot password?
                </a>
              </div>

              <div className="mt-7">
                <Button
                  type="submit"
                  variant="primary"
                  className="!rounded-full w-full"
                  disabled={disabled || loading}
                  loading={loading}
                >
                  Login
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {!!showChangePassword && (
        <ChangePassword
          isOpen={!!showChangePassword}
          onClose={handleChangePasswordClose}
          onSave={handlePasswordChange(showChangePassword)}
          loading={changePasswordLoading}
          // user={showChangePassword}
          // fromLogin={true}
        />
      )}
      {showOtp?.isOpen && (
        <MFASetup
          isOpen={showOtp.isOpen}
          loading={verificationLoading}
          onClose={() => setShowOtp({ isOpen: false, email: "" })}
          authCode={authCode}
          setAuthCode={setAuthCode}
          verifyTotp={mfaSecret?.code ? verifySoftwareToken : verifyTotp}
          mfaSecret={mfaSecret}
          onSetupMFA={() => handleSetupMFA(showOtp.email)}
          tenantProfile={tenantProfile}
        />
      )}
    </div>
  );
}
