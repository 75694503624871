import { useNavigate } from "react-router-dom";
import Successful from "../../../assets/icons/success.json";

import { Button } from "../../../components/Button";
import Lottie from "lottie-react";

export function ForgotPasswordSuccess({ email }: { email: string }) {
  const navigate = useNavigate();
  const handleLoginClick = () => {
    navigate("/login");
  };
  return (
    <div className="rounded-lg w-[438px] p-8 bg-white flex flex-col items-center mx-3">
      <Lottie
        animationData={Successful}
        loop={false}
        initialSegment={[0, 37]}
        style={{
          height: "122px",
        }}
      />
      <div className="text-black text-[28px] font-semibold leading-loose text-center mt-1 mb-4">
        Reset link sent
      </div>
      <span className="text-black/80 text-center text-sm font-normal leading-normal ">
        We've sent a link to your registered email address, '{email}'. Please
        click the link and follow the steps to change your password.
      </span>

      <Button
        className="!rounded-full w-full mt-8"
        variant="primary"
        onClick={handleLoginClick}
      >
        Go to login
      </Button>
    </div>
  );
}
